<template>
<div v-if="board" class="board-container">
        <table class="board-view">
            <tr>
                <th colspan="2">{{ board.subject }}</th>
            </tr>
            <tr>
                <td colspan="2">
                    <v-row class="row--small">
                        <v-col cols="auto">
                            <span>{{$t('common.작성자')}} : {{ board.writer.name }}</span>
                        </v-col>
                        <v-col cols="auto">
                            <span>{{$t('common.작성일')}} : {{ board.createdAt.toDate() }}</span>
                        </v-col>
                        <v-col cols="auto">
                            <span>{{$t('common.조회수')}} : {{ board.viewCount }}</span>
                        </v-col>
                    </v-row>
                </td>
            </tr>
            <tr>
                <td colspan="2" class="content_box">
                    <v-expand-transition>
                        <v-responsive v-show="showYoutube" :aspect-ratio="16 / 9">
                            <youtube v-bind="{ videoId }" fitParent @ready="onReady" style="width:100%;" />
                        </v-responsive>
                    </v-expand-transition>
                    <div v-html="board.content"></div>
                </td>
            </tr>
        </table>

        <table v-show="false" class="board-view-navi">
            <tr>
                <th width="20%">이전글</th>
                <td><a href="">이전 게시글 제목입니다.</a></td>
            </tr>
            <tr>
                <th width="20%">다음글</th>
                <td><a href="">다음 게시글 제목입니다.</a></td>
            </tr>
        </table>

        <div class="board-bottom">
            <div v-show="false" class="v-btn--group justify-end">
                <v-btn large color="primary">수정</v-btn>
                <v-btn large outlined color="primary">삭제</v-btn>
                <v-btn large outlined color="primary" class="grey-2--text">답글</v-btn>
            </div>
            <div class="v-btn--group justify-end">
                <v-btn @click="$router.go(-1)" large color="primary">{{$t("common.list")}}</v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import CommentList from "../../comment-list.vue";
import urlParser from "js-video-url-parser";

export default {
    components: {
        CommentList,
    },
    props: {
        board: { type: Object, default: null },
        me: { type: Object, default: null },
    },
    data: () => ({
        showYoutube: false,
    }),
    methods: {
        navigate(){
            window.location.href = this.board?.meta?.buyURL;
        },
        onReady() {
            this.showYoutube = true;
        },
    },
    computed: {
        isBookmark() {
            return this.me ? this.me.bookmarks.find(bookmark => bookmark._board == this.board._id) : false;
        },
        isLike() {
            return this.me ? this.me.likes.find(like => like._board == this.board._id) : false;
        },
        videoId() {
            return urlParser.parse(this.board?.meta?.youtube)?.id || "";
        },
    },
};
</script>
